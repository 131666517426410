import React from "react";
import {skillsSection} from "../portfolio";

export default function SoftwareSkill() {
  return (
    <div>
      <div className="software-skills-main-div">
        <ul className="dev-icons">
          {skillsSection.softwareSkills.map((skills, i) => {
            return (
              <li
                key={i}
                className="software-skill-inline"
                name={skills.skillName}
              >
                          <p><img src={skills.image} style={{alignSelf:'center',marginTop:20}} 
 alt="Avatar" /></p>
                {/* <i style={{color:"#aaa"}} className={skills.fontAwesomeClassname}></i> */}
                <h4>{skills.skillName}</h4>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}
