import React from 'react'

function Header() {
  return (
    <div className="top-nav" id="home">
    <div class="container">
      <div class="row justify-content-between">
        <div class="col-auto">
          <p> <i class='bx bxs-envelope'></i>
          
           sales@webiopr.com</p>
          <p> <i class='bx bxs-phone-call'></i>
          <a  href="tel:+919455605492" className="link-secondary">
                          
                            +91-9455605492
                          </a>
          </p>
       
        </div>
        <div class="col-auto social-icons">
          {/* <a href="/a"><i class='bx bxl-facebook'></i></a> */}
          <a href="https://x.com/webiopr" target="_blank" rel="noopener noreferrer"><i class='bx bxl-twitter'></i></a>
          <a href="https://www.instagram.com/webiopr/" target="_blank" rel="noopener noreferrer"><i class='bx bxl-instagram'></i></a>
          <a href="https://www.linkedin.com/company/webiopr" target="_blank" rel="noopener noreferrer"><i class='bx bxl-linkedin'></i></a>

          {/* <a href="/d"><i class='bx bxl-pinterest'></i></a> */}
        </div>
      </div>
    </div>
  </div>  )
}

export default Header