// import logo from './logo.svg';
import './css/style.css';
// import Main from './Main';
import 'animate.css';
import Header from './component/Header';
import NavBar from './component/NavBar';
import Silder from './component/Silder';
import AboutPage from './component/AboutPage';
import MileStonePage from './component/MileStonePage';
import ServicePage from './component/ServicePage';
// import PortFolioPage from './component/PortFolioPage';
import TeamPage from './component/TeamPage';
// import ReviewPage from './component/ReviewPage';
import BlogPage from './component/BlogPage';
import Footer from './component/Footer';
import splashAnimation from "./assets/lottie/splashAnimation.json";
import SplashScreen from './component/SplashScreen';
import React,{ useEffect, useState } from 'react';
import { StyleProvider } from './contexts/StyleContext';
import { useLocalStorage } from './hooks/useLocalStorage';
import Skills from './component/Skills';
import Contact from './component/Contact';
// import ContactForm from './component/ContactForm';
import ScrollToTopButton from "./component/topbutton/Top";
import Step from './component/Step';

const splashScreen = {
  enabled: true, // set false to disable splash screen
  animation: splashAnimation,
  duration: 2000 // Set animation duration as per your animation
};
function App() {
  const [isShowingSplashAnimation, setIsShowingSplashAnimation] =
  useState(true);

  const darkPref = window.matchMedia("(prefers-color-scheme: dark)");
  const [isDark, setIsDark] = useLocalStorage("isDark", darkPref.matches);
  useEffect(() => {
    if (splashScreen.enabled) {
      const splashTimer = setTimeout(
        () => setIsShowingSplashAnimation(false),
        splashScreen.duration
      );
      return () => {
        clearTimeout(splashTimer);
      };
    }
  }, []);
  const changeTheme = () => {
    setIsDark(!isDark);
  };

  return (
    <>
      <StyleProvider
      value={{ changeTheme: changeTheme}}
      >
         {isShowingSplashAnimation && splashScreen.enabled ? (
          <SplashScreen />
        ) : (
    <>
    
    <Header/>
  <NavBar/>
<Silder/>

   <AboutPage/>
   <MileStonePage/>
   <ServicePage/>
   <Skills/>
   {/* <PortFolioPage/> */}
   <TeamPage/>
   {/* <ReviewPage/> */}
   <BlogPage/>
   <Contact/>
   {/* <Step/> */}
   <Footer/>
{/* <ScrollToTopButton/> */}
   
  </>)}
  </StyleProvider>
  </>
  );


// return (
// <>

//    <header>
//       <div class="header">
//          <div class="header_to d_none">
//          <div class="container">
//                <div class="row d_flex">
//                   <div class="col-md-4 col-sm-4 d_none">
//                      <ul class="conta_icon">
//                         <li><a href="#"><i class="fa fa-phone" aria-hidden="true"></i> Call Us : +01 1234567890</a> </li>
//                      </ul>
//                   </div>
//                   <div class="col-md-4 col-sm-4 ">
//                      <a class="logo" href="#"><img  src={require("./img/Heading.png")} alt="#" style={{height:100}} /></a>
//                   </div>
//                   <div class="col-md-4 col-sm-4 d_none">
//                      <ul class="conta_icon ">
//                         <li><a href="#"><i class="fa fa-envelope" aria-hidden="true"></i> demo@gmail.com</a> </li>
//                      </ul>
//                   </div>
//                </div>
//             </div>
//          </div>
//          {/* <div class="header_midil">
//             <div class="container">
//                <div class="row d_flex">
//                   <div class="col-md-4 col-sm-4 d_none">
//                      <ul class="conta_icon">
//                         <li><a href="#"><i class="fa fa-phone" aria-hidden="true"></i> Call Us : +01 1234567890</a> </li>
//                      </ul>
//                   </div>
//                   <div class="col-md-4 col-sm-4 ">
//                      <a class="logo" href="#"><img src="images/logo.png" alt="#" /></a>
//                   </div>
//                   <div class="col-md-4 col-sm-4 d_none">
//                      <ul class="conta_icon ">
//                         <li><a href="#"><i class="fa fa-envelope" aria-hidden="true"></i> demo@gmail.com</a> </li>
//                      </ul>
//                   </div>
//                </div>
//             </div>
//          </div> */}
//          <div class="header_bo">
//             <div class="container">
//                <div class="row">
//                   <div class="col-md-9 col-sm-7">
//                      <nav class="navigation navbar navbar-expand-md navbar-dark ">
//                         <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExample04" aria-controls="navbarsExample04" aria-expanded="false" aria-label="Toggle navigation">
//                            <span class="navbar-toggler-icon"></span>
//                         </button>
//                         <div class="collapse navbar-collapse" id="navbarsExample04">
//                         <div class="col-md-4 col-sm-4 ">
//                      <a class="logo" href="#"><img  src={require("./img/Heading.png")} alt="#" style={{height:80}} /></a>
//                   </div>
//                            <ul class="navbar-nav mr-auto">
//                               <li class="nav-item active">
//                                  <a class="nav-link" href="index.html"> Home </a>
//                               </li>
//                               <li class="nav-item">
//                                  <a class="nav-link" href="about.html">about</a>
//                               </li>
//                               <li class="nav-item">
//                                  <a class="nav-link" href="service.html">services</a>
//                               </li>
//                               <li class="nav-item">
//                                  <a class="nav-link" href="team.html">team </a>
//                               </li>
//                               <li class="nav-item">
//                                  <a class="nav-link" href="client.html">Clients</a>
//                               </li>
//                               <li class="nav-item">
//                                  <a class="nav-link" href="contact.html"> contact us </a>
//                               </li>
//                            </ul>
//                         </div>
//                      </nav>
//                   </div>
//                   <div class="col-md-3 col-sm-5 d_none">
//                      <ul class="sign">
//                         <li><a href="#"><i class="fa fa-search" aria-hidden="true"></i></a></li>
//                         <li><a class="sign_btn" href="#">sign up now</a></li>
//                      </ul>
//                   </div>
//                </div>
//             </div>
//          </div>
//       </div>
//    </header>
//    <section className="banner_main">
//       <div className="container">
//          <div className="row">
//             <div className="col-md-7 col-lg-7">
//                <div className="text-bg">
//                   <h1>Trusted and <br/>Professional Advisers</h1>
//                   <span>for your Business</span>
//                   <p>Showcase your Profile to the world using online CV builder and Get Hired Faster</p>
//                   <a href="#">About us</a>
//                </div>
//             </div>
//             <div className="col-md-5 col-lg-5">
//                <div className="ban_img">
//                   <figure><img src={require("./img/images/ba_ing.png" )}alt="#" /></figure>
//                </div>
//             </div>
//          </div>
//       </div>
//    </section>
//    <div id="about" className="about">
//       <div className="container-fluid">
//          <div className="row">
//             <div className="col-md-12 col-lg-7">
//                <div className="about_box">
//                   <div className="titlepage">
//                      <h2><strong className="yellow">About US</strong><br/> WE CAN HELP YOUR business GROW</h2>
//                   </div>
//                   <h3>EVERYTHING YOU NEED IN ONE SOLUTION</h3>
//                   <span>HELP YOUR NEXT CAREER MOVE MORE SMOOTHER AND <br/> MORE EFFICIENT</span>
//                   <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of usingt</p>
//                   <span className="try">TRY ONLINE CV BUILDER FOR Free</span>
//                   <a className="read_morea" href="#">Get Started <i className="fa fa-angle-right" aria-hidden="true"></i></a>
//                </div>
//             </div>
//             <div className="col-md-12 col-lg-5">
//                <div className="about_img">
//                   <figure><img src={require("./img/images/about_img2.jpg")} alt="#" /></figure>
//                </div>
//             </div>
//          </div>
//       </div>
//    </div>
//    <div id="service" className="service">
//       <div className="container">
//          <div className="row">
//             <div className="col-md-7">
//                <div className="titlepage">
//                   <h2><strong className="yellow">service</strong><br/> WE CAN HELP YOUR business GROW</h2>
//                </div>
//             </div>
//          </div>
//          <div className="row">
//             <div className="col-md-4 col-sm-6">
//                <div id="ho_color" className="service_box">
//                   <img src={require("./img/images/service_icon1.png")} alt="#" />
//                   <h3>DIGITAL marketing</h3>
//                   <p>Lorem Ipsum is simply dummy text of the printing and1500s, </p>
//                </div>
//             </div>
//             <div className="col-md-4 col-sm-6">
//                <div id="ho_color" className="service_box">
//                   <img src={require("./img/images/service_icon2.png")} alt="#" />
//                   <h3>FINANCIAL PLANING</h3>
//                   <p>Lorem Ipsum is simply dummy text of the printing and1500s, </p>
//                </div>
//             </div>
//             <div className="col-md-4 col-sm-6">
//                <div id="ho_color" className="service_box">
//                   <img src={require("./img/images/service_icon3.png")} alt="#" />
//                   <h3>DIGITAL marketing</h3>
//                   <p>Lorem Ipsum is simply dummy text of the printing and1500s, </p>
//                </div>
//             </div>
//             <div className="col-md-4 col-sm-6">
//                <div id="ho_color" className="service_box">
//                   <img src={require("./img/images/service_icon4.png")} alt="#" />
//                   <h3>BUSINESS CONSULTING</h3>
//                   <p>Lorem Ipsum is simply dummy text of the printing and1500s, </p>
//                </div>
//             </div>
//             <div className="col-md-4 col-sm-6">
//                <div id="ho_color" className="service_box">
//                   <img src={require("./img/images/service_icon5.png")} alt="#" />
//                   <h3>MARKETING RESEARCH</h3>
//                   <p>Lorem Ipsum is simply dummy text of the printing and1500s, </p>
//                </div>
//             </div>
//             <div className="col-md-4 col-sm-6">
//                <div id="ho_color" className="service_box">
//                   <img src={require("./img/images/service_icon6.png")} alt="#" />
//                   <h3>UX RESEARCH</h3>
//                   <p>Lorem Ipsum is simply dummy text of the printing and1500s, </p>
//                </div>
//             </div>
//             <div className="col-md-12">
//                <a className="read_more" href="#">Read More</a>
//             </div>
//          </div>
//       </div>
//    </div>
//    <div className="portfolio">
//       <div className="container">
//          <div className="row">
//             <div className="col-md-12 ">
//                <div className="titlepage">
//                   <h2><strong className="yellow">PORTFOLIO</strong><br/> CHOOSE A PROFESSIONAL DESIGN</h2>
//                   <span>Websites</span>
//                   <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distrib</p>
//                </div>
//             </div>
//          </div>
//       </div>
//       <div id="myCarousel" className="carousel slide portfolio_Carousel " data-ride="carousel">
//          <ol className="carousel-indicators">
//             <li data-target="#myCarousel" data-slide-to="0" className="active"></li>
//             <li data-target="#myCarousel" data-slide-to="1"></li>
//             <li data-target="#myCarousel" data-slide-to="2"></li>
//          </ol>
//          <div className="carousel-inner">
//             <div className="carousel-item active">
//                <div className="container">
//                   <div className="carousel-caption ">
//                      <div className="row">
//                         <div className="col-md-3 col-sm-6">
//                            <div className="portfolio_img">
//                               <img src={require("./img/images/potf1.jpg")} alt="#" />
//                               <div className="middle">
//                                  <div className="text2">View More</div>
//                               </div>
//                            </div>
//                         </div>
//                         <div className="col-md-3 col-sm-6">
//                            <div className="portfolio_img">
//                               <img src={require("./img/images/potf2.jpg")} alt="#" />
//                               <div className="middle">
//                                  <div className="text2">View More</div>
//                               </div>
//                            </div>
//                         </div>
//                         <div className="col-md-3 col-sm-6">
//                            <div className="portfolio_img">
//                               <img src={require("./img/images/potf3.jpg")} alt="#" />
//                               <div className="middle">
//                                  <div className="text2">View More</div>
//                               </div>
//                            </div>
//                         </div>
//                         <div className="col-md-3 col-sm-6">
//                            <div className="portfolio_img">
//                               <img src={require("./img/images/potf4.jpg")} alt="#" />
//                               <div className="middle">
//                                  <div className="text2">View More</div>
//                               </div>
//                            </div>
//                         </div>
//                         <div className="col-md-12">
//                            <a className="read_more" href="#">Read More</a>
//                         </div>
//                      </div>
//                   </div>
//                </div>
//             </div>
//             <div className="carousel-item">
//                <div className="container">
//                   <div className="carousel-caption">
//                      <div className="row">
//                         <div className="col-md-3 col-sm-6">
//                            <div className="portfolio_img">
//                               <img src={require("./img/images/potf1.jpg" )}alt="#" />
//                               <div className="middle">
//                                  <div className="text2">View More</div>
//                               </div>
//                            </div>
//                         </div>
//                         <div className="col-md-3 col-sm-6">
//                            <div className="portfolio_img">
//                               <img src={require("./img/images/potf2.jpg")} alt="#" />
//                               <div className="middle">
//                                  <div className="text2">View More</div>
//                               </div>
//                            </div>
//                         </div>
//                         <div className="col-md-3 col-sm-6">
//                            <div className="portfolio_img">
//                               <img src={require("./img/images/potf3.jpg")} alt="#" />
//                               <div className="middle">
//                                  <div className="text2">View More</div>
//                               </div>
//                            </div>
//                         </div>
//                         <div className="col-md-3 col-sm-6">
//                            <div className="portfolio_img">
//                               <img src={require("./img/images/potf4.jpg")} alt="#" />
//                               <div className="middle">
//                                  <div className="text2">View More</div>
//                               </div>
//                            </div>
//                         </div>
//                         <div className="col-md-12">
//                            <a className="read_more" href="#">Read More</a>
//                         </div>
//                      </div>
//                   </div>
//                </div>
//             </div>
//             <div className="carousel-item">
//                <div className="container">
//                   <div className="carousel-caption">
//                      <div className="row">
//                         <div className="col-md-3 col-sm-6">
//                            <div className="portfolio_img">
//                               <img src={require("./img/images/potf1.jpg")} alt="#" />
//                               <div className="middle">
//                                  <div className="text2">View More</div>
//                               </div>
//                            </div>
//                         </div>
//                         <div className="col-md-3 col-sm-6">
//                            <div className="portfolio_img">
//                               <img src={require("./img/images/potf2.jpg")} alt="#" />
//                               <div className="middle">
//                                  <div className="text2">View More</div>
//                               </div>
//                            </div>
//                         </div>
//                         <div className="col-md-3 col-sm-6">
//                            <div className="portfolio_img">
//                               <img src={require("./img/images/potf3.jpg")} alt="#" />
//                               <div className="middle">
//                                  <div className="text2">View More</div>
//                               </div>
//                            </div>
//                         </div>
//                         <div className="col-md-3 col-sm-6">
//                            <div className="portfolio_img">
//                               <img src={require("./img/images/potf4.jpg")} alt="#" />
//                               <div className="middle">
//                                  <div className="text2">View More</div>
//                               </div>
//                            </div>
//                         </div>
//                         <div className="col-md-12">
//                            <a className="read_more" href="#">Read More</a>
//                         </div>
//                      </div>
//                   </div>
//                </div>
//             </div>
//          </div>
//          <a className="carousel-control-prev" href="#myCarousel" role="button" data-slide="prev">
//             <i className="fa fa-chevron-left" aria-hidden="true"></i>
//          </a>
//          <a className="carousel-control-next" href="#myCarousel" role="button" data-slide="next">
//             <i className="fa fa-chevron-right" aria-hidden="true"></i>
//          </a>
//       </div>
//    </div>
   
//    <div className="business">
//       <div className="container">
//          <div className="row">
//             <div className="col-md-7">
//                <div className="titlepage">
//                   <h2><strong className="yellow">CREATE</strong><br/>PERSONALISED Business</h2>
//                   <p>ss normal distribution of letters, as opposed to using </p>
//                </div>
//             </div>
//          </div>
//          <div className="row">
//             <div className="col-md-3 col-sm-6">
//                <div id="ho_color" className="business_box">
//                   <i><img src={require("./img/images/business_icon1.png")} alt="#" /></i>
//                   <h3>23</h3>
//                   <p>NOMINATIONS </p>
//                </div>
//             </div>
//             <div className="col-md-3 col-sm-6">
//                <div id="ho_color" className="business_box">
//                   <i><img src={require("./img/images/business_icon2.png")} alt="#" /></i>
//                   <h3>31</h3>
//                   <p>AGENCIES</p>
//                </div>
//             </div>
//             <div className="col-md-3 col-sm-6">
//                <div id="ho_color" className="business_box">
//                   <i><img src={require("./img/images/business_icon3.png")} alt="#" /></i>
//                   <h3>7</h3>
//                   <p>AWARDS</p>
//                </div>
//             </div>
//             <div className="col-md-3 col-sm-6">
//                <div id="ho_color" className="business_box">
//                   <i><img src={require("./img/images/business_icon4.png")} alt="#" /></i>
//                   <h3>8</h3>
//                   <p>Supported </p>
//                </div>
//             </div>
//             <div className="col-md-12">
//                <a className="read_more" href="#">Read More</a>
//             </div>
//          </div>
//       </div>
//    </div>
//    <div id="team" className="team">
//       <div className="container">
//          <div className="row">
//             <div className="col-md-12">
//                <div className="titlepage">
//                   <h2><strong className="yellow">Team</strong><br/>We Have a Professional Team of Business Analysts.</h2>
//                </div>
//             </div>
//          </div>
//          <div className="row">
//             <div className="col-md-12">
//                <div id="team" className="carousel slide team_Carousel " data-ride="carousel">
//                   <ol className="carousel-indicators">
//                      <li data-target="#team" data-slide-to="0" className="active"></li>
//                      <li data-target="#team" data-slide-to="1"></li>
//                      <li data-target="#team" data-slide-to="2"></li>
//                   </ol>
//                   <div className="carousel-inner">
//                      <div className="carousel-item active">
//                         <div className="container">
//                            <div className="carousel-caption ">
//                               <div className="row">
//                                  <div className="col-md-4 col-sm-6">
//                                     <div id="ho_bg" className="team_img">
//                                        <img src={require("./img/images/team1.png")} alt="#" />
//                                        <div className="ho_socal">
//                                           <ul className="social_icont">
//                                              <li> <a href="#"><i className="fa fa-facebook-f"></i></a></li>
//                                              <li> <a href="#"><i className="fa fa-twitter"></i></a></li>
//                                              <li> <a href="#"> <i className="fa fa-linkedin" aria-hidden="true"></i></a></li>
//                                              <li> <a href="#"><i className="fa fa-instagram"></i></a></li>
//                                           </ul>
//                                        </div>
//                                     </div>
//                                  </div>
//                                  <div className="col-md-4 col-sm-6">
//                                     <div id="ho_bg" className="team_img">
//                                        <img src={require("./img/images/team2.png" )}alt="#" />
//                                        <div className="ho_socal">
//                                           <ul className="social_icont">
//                                              <li> <a href="#"><i className="fa fa-facebook-f"></i></a></li>
//                                              <li> <a href="#"><i className="fa fa-twitter"></i></a></li>
//                                              <li> <a href="#"> <i className="fa fa-linkedin" aria-hidden="true"></i></a></li>
//                                              <li> <a href="#"><i className="fa fa-instagram"></i></a></li>
//                                           </ul>
//                                        </div>
//                                     </div>
//                                  </div>
//                                  <div className="col-md-4 col-sm-6 d_none1">
//                                     <div id="ho_bg" className="team_img ">
//                                        <img src={require("./img/images/team3.png")} alt="#" />
//                                        <div className="ho_socal">
//                                           <ul className="social_icont">
//                                              <li> <a href="#"><i className="fa fa-facebook-f"></i></a></li>
//                                              <li> <a href="#"><i className="fa fa-twitter"></i></a></li>
//                                              <li> <a href="#"> <i className="fa fa-linkedin" aria-hidden="true"></i></a></li>
//                                              <li> <a href="#"><i className="fa fa-instagram"></i></a></li>
//                                           </ul>
//                                        </div>
//                                     </div>
//                                  </div>
//                               </div>
//                            </div>
//                         </div>
//                      </div>
//                      <div className="carousel-item">
//                         <div className="container">
//                            <div className="carousel-caption">
//                               <div className="row">
//                                  <div className="col-md-4 col-sm-6 d_none1">
//                                     <div id="ho_bg" className="team_img ">
//                                        <img src={require("./img/images/team1.png" )}alt="#" />
//                                        <div className="ho_socal">
//                                           <ul className="social_icont">
//                                              <li> <a href="#"><i className="fa fa-facebook-f"></i></a></li>
//                                              <li> <a href="#"><i className="fa fa-twitter"></i></a></li>
//                                              <li> <a href="#"> <i className="fa fa-linkedin" aria-hidden="true"></i></a></li>
//                                              <li> <a href="#"><i className="fa fa-instagram"></i></a></li>
//                                           </ul>
//                                        </div>
//                                     </div>
//                                  </div>
//                                  <div className="col-md-4 col-sm-6">
//                                     <div id="ho_bg" className="team_img">
//                                        <img src={require("./img/images/team2.png")} alt="#" />
//                                        <div className="ho_socal">
//                                           <ul className="social_icont">
//                                              <li> <a href="#"><i className="fa fa-facebook-f"></i></a></li>
//                                              <li> <a href="#"><i className="fa fa-twitter"></i></a></li>
//                                              <li> <a href="#"> <i className="fa fa-linkedin" aria-hidden="true"></i></a></li>
//                                              <li> <a href="#"><i className="fa fa-instagram"></i></a></li>
//                                           </ul>
//                                        </div>
//                                     </div>
//                                  </div>
//                                  <div className="col-md-4 col-sm-6">
//                                     <div id="ho_bg" className="team_img">
//                                        <img src={require("./img/images/team3.png" )}alt="#" />
//                                        <div className="ho_socal">
//                                           <ul className="social_icont">
//                                              <li> <a href="#"><i className="fa fa-facebook-f"></i></a></li>
//                                              <li> <a href="#"><i className="fa fa-twitter"></i></a></li>
//                                              <li> <a href="#"> <i className="fa fa-linkedin" aria-hidden="true"></i></a></li>
//                                              <li> <a href="#"><i className="fa fa-instagram"></i></a></li>
//                                           </ul>
//                                        </div>
//                                     </div>
//                                  </div>
//                               </div>
//                            </div>
//                         </div>
//                      </div>
//                      <div className="carousel-item">
//                         <div className="container">
//                            <div className="carousel-caption">
//                               <div className="row">
//                                  <div className="col-md-4 col-sm-6">
//                                     <div id="ho_bg" className="team_img">
//                                        <img src={require("./img/images/team1.png")} alt="#" />
//                                        <div className="ho_socal">
//                                           <ul className="social_icont">
//                                              <li> <a href="#"><i className="fa fa-facebook-f"></i></a></li>
//                                              <li> <a href="#"><i className="fa fa-twitter"></i></a></li>
//                                              <li> <a href="#"> <i className="fa fa-linkedin" aria-hidden="true"></i></a></li>
//                                              <li> <a href="#"><i className="fa fa-instagram"></i></a></li>
//                                           </ul>
//                                        </div>
//                                     </div>
//                                  </div>
//                                  <div className="col-md-4 col-sm-6 d_none1">
//                                     <div id="ho_bg" className="team_img ">
//                                        <img src={require("./img/images/team2.png")} alt="#" />
//                                        <div className="ho_socal">
//                                           <ul className="social_icont">
//                                              <li> <a href="#"><i className="fa fa-facebook-f"></i></a></li>
//                                              <li> <a href="#"><i className="fa fa-twitter"></i></a></li>
//                                              <li> <a href="#"> <i className="fa fa-linkedin" aria-hidden="true"></i></a></li>
//                                              <li> <a href="#"><i className="fa fa-instagram"></i></a></li>
//                                           </ul>
//                                        </div>
//                                     </div>
//                                  </div>
//                                  <div className="col-md-4 col-sm-6">
//                                     <div id="ho_bg" className="team_img">
//                                        <img src={require("./img/images/team3.png" )}alt="#" />
//                                        <div className="ho_socal">
//                                           <ul className="social_icont">
//                                              <li> <a href="#"><i className="fa fa-facebook-f"></i></a></li>
//                                              <li> <a href="#"><i className="fa fa-twitter"></i></a></li>
//                                              <li> <a href="#"> <i className="fa fa-linkedin" aria-hidden="true"></i></a></li>
//                                              <li> <a href="#"><i className="fa fa-instagram"></i></a></li>
//                                           </ul>
//                                        </div>
//                                     </div>
//                                  </div>
//                               </div>
//                            </div>
//                         </div>
//                      </div>
//                   </div>
//                   <a className="carousel-control-prev" href="#team" role="button" data-slide="prev">
//                      <i className="fa fa-chevron-left" aria-hidden="true"></i>
//                   </a>
//                   <a className="carousel-control-next" href="#team" role="button" data-slide="next">
//                      <i className="fa fa-chevron-right" aria-hidden="true"></i>
//                   </a>
//                </div>
//             </div>
//          </div>
//       </div>
//    </div>
//    <div id="client" className="testimonial">
//       <div className="container">
//          <div className="row">
//             <div className="col-md-12">
//                <div className="titlepage">
//                   <h2><strong className="yellow">testimonial</strong><br/>What is Syas our clients</h2>
//                </div>
//             </div>
//          </div>
//       </div>
//       <div id="testimo" className="carousel slide testimonial_Carousel " data-ride="carousel">
//          <ol className="carousel-indicators">
//             <li data-target="#testimo" data-slide-to="0" className="active"></li>
//             <li data-target="#testimo" data-slide-to="1"></li>
//             <li data-target="#testimo" data-slide-to="2"></li>
//          </ol>
//          <div className="carousel-inner">
//             <div className="carousel-item active">
//                <div className="container">
//                   <div className="carousel-caption ">
//                      <div className="row">
//                         <div className="col-md-6 offset-md-3">
//                            <div className="test_box">
//                               <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis </p>
//                               <i><img src={require("./img/images/cos.jpg")} alt="#" /></i> <span>Consectetur</span>
//                            </div>
//                         </div>
//                      </div>
//                   </div>
//                </div>
//             </div>
//             <div className="carousel-item">
//                <div className="container">
//                   <div className="carousel-caption">
//                      <div className="row">
//                         <div className="col-md-6 offset-md-3">
//                            <div className="test_box">
//                               <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis </p>
//                               <i><img src={require("./img/images/cos.jpg")} alt="#" /></i> <span>Consectetur</span>
//                            </div>
//                         </div>
//                      </div>
//                   </div>
//                </div>
//             </div>
//             <div className="carousel-item">
//                <div className="container">
//                   <div className="carousel-caption">
//                      <div className="row">
//                         <div className="col-md-6 offset-md-3">
//                            <div className="test_box">
//                               <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis </p>
//                               <i><img src={require("./img/images/cos.jpg")} alt="#" /></i> <span>Consectetur</span>
//                            </div>
//                         </div>
//                      </div>
//                   </div>
//                </div>
//             </div>
//          </div>
//          <a className="carousel-control-prev" href="#testimo" role="button" data-slide="prev">
//             <i className="fa fa-chevron-left" aria-hidden="true"></i>
//          </a>
//          <a className="carousel-control-next" href="#testimo" role="button" data-slide="next">
//             <i className="fa fa-chevron-right" aria-hidden="true"></i>
//          </a>
//       </div>
//    </div>
//    <div id="contact" className="contact ">
//       <div className="container">
//          <div className="row">
//             <div className="col-md-12">
//                <div className="titlepage">
//                   <h2><strong className="yellow">Contact us</strong><br/>Request a call back</h2>
//                </div>
//             </div>
//          </div>
//          <div className="row">
//             <div className="col-md-8 offset-md-2">
//                <form id="post_form" className="contact_form">
//                   <div className="row">
//                      <div className="col-md-12 ">
//                         <input className="contact_control" placeholder=" Name" type="type" name="Name"/>
//                      </div>
//                      <div className="col-md-12">
//                         <input className="contact_control" placeholder="Email" type="type" name="Email"/>
//                      </div>
//                      <div className="col-md-12">
//                         <input className="contact_control" placeholder="Phone Number " type="type" name="Phone Number "/>
//                      </div>
//                      <div className="col-md-12">
//                         <textarea className="textarea" placeholder="Message" type="type" Message="Name">Message </textarea>
//                      </div>
//                      <div className="col-md-12">
//                         <button className="send_btn">Send</button>
//                      </div>
//                      </div>
//                </form>
//             </div>
//          </div>
//       </div>
//    </div>
//    <footer>
//       <div className="footer">
//          <div className="container">
//             <div className="row">
//                <div className="col-md-12">
//                   <a className="logo2" href="#"><img src={require("./img/images/loogo2.png")} alt="#" /></a>
//                </div>
//                <div className="col-lg-5 col-md-6 col-sm-6">
//                   <h3>Contact Us</h3>
//                   <ul className="location_icon">
//                      <li><a href="#"><i className="fa fa-map-marker" aria-hidden="true"></i></a> London 145
//                         <br/> United Kingdom
//                      </li>
//                      <li><a href="#"><i className="fa fa-envelope" aria-hidden="true"></i></a>demo@gmail.com<br/> demo@gmail.com</li>
//                      <li><a href="#"><i className="fa fa-volume-control-phone" aria-hidden="true"></i></a>+01 1234567890<br/>+01 1234567889</li>
//                   </ul>
//                   <ul className="social_icon">
//                      <li> <a href="#"><i className="fa fa-facebook-f"></i></a></li>
//                      <li> <a href="#"><i className="fa fa-twitter"></i></a></li>
//                      <li> <a href="#"> <i className="fa fa-linkedin" aria-hidden="true"></i></a></li>
//                      <li> <a href="#"><i className="fa fa-instagram"></i></a></li>
//                   </ul>
//                </div>
//                <div className="col-lg-2 col-md-6 col-sm-6">
//                   <h3>Menus</h3>
//                   <ul className="link_icon">
//                      <li className="active"> <a href="index.html"> Home</a></li>
//                      <li>
//                         <a href="about.html">
//                            <i>About Us</i>
//                            </a>
//                      </li>
//                      <li> <a href="service.html"> <i>Services</i></a></li>
//                      <li> <a href="team.html"><i>Team</i></a></li>
//                      <li> <a href="client.html"><i>Clients</i></a></li>
//                      <li> <a href="contact.html"><i>Contact us</i></a></li>
//                   </ul>
//                </div>
//                <div className="col-lg-2 col-md-6 col-sm-6">
//                   <h3>Recent Post</h3>
//                   <ul className="link_icon">
//                      <li> <a href="#"> Participate in staff </a></li>
//                      <li>
//                         <a href="#">
//                            meetings manage
//                            </a>
//                      </li>
//                      <li> <a href="#"> dedicated to </a></li>
//                      <li> <a href="#"> marketing</a></li>
//                      <li> <a href="#"> November 25, 2019</a></li>
//                   </ul>
//                </div>
//                <div className="col-lg-3 col-md-6 col-sm-6">
//                   <h3>Newsletter</h3>
//                   <form id="request" className="main_form">
//                      <div className="row">
//                         <div className="col-md-12 ">
//                            <input className="news" placeholder="Your Email" type="type" name="Your Email"/>
//                         </div>
//                         <div className="col-md-12">
//                            <button className="send_btn">Send</button>
//                         </div>
//                      </div>
//                   </form>
//                </div>
//             </div>
//          </div>
//          <div className="copyright">
//             <div className="container">
//                <div className="row">
//                   <div className="col-md-12">
//                      <p>© 2019 All Rights Reserved.<a href="https://html.design/"> Free html Templates</a></p>
//                   </div>
//                </div>
//             </div>
//          </div>
//          </div>
//          </footer>
// </>
// )

}

export default App;
