import React from 'react'
import { Fade } from 'react-reveal'

function ServicePage() {
  return (
    <Fade right duration={1000}>

    <section id="services" class="text-center">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="intro">
                <h6>Our Services</h6>
                <h1>What We Do?</h1>
                {/* <p class="mx-auto">Contrary to popular belief, Lorem Ipsum is not simply random text. It has
                  roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old</p> */}
              </div>
            </div>
          </div>
          <div class="row g-4">
            <div class="col-lg-4 col-md-6">
              <div class="service">
              <img src={require("../img/digi.webp")} alt=""/>
                  <h5>Digital Marketing</h5>
                  <p>Digital marketing comprises all marketing efforts that use the internet. These include digital channels such as search engines, email, websites, social media, etc., that can be used to connect with current and prospective customers</p>
          </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="service">
              <img src={require("../img/images/mobapp.png")} alt=""/>
                  <h5>Mobile App</h5>
                  <p>We offer Next-gen technologies, intelligent algorithms and modern UI Custom iOS and Android apps development,Native and cross-platform solutions,Mobile application development based on React Native and Flutter,Swift, Kotlin</p>
          </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="service">
              <img src={require("../img/images/paymentgatway.png")} alt=""/>
                  <h5>Payment Gateway</h5>
                  <p>Affordable Web/App development services in India, includes All type of payment gateway for easy online transfer from any customer (UPI /debit/ credit etc) account to your account</p>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="service">
              <img src={require("../img/ecom.png")} alt=""/>
                  <h5>Ecommerce</h5>
                  <p>Responsive Ecommerce Website is the approach that suggests website design and development should respond to the user’s request irrespective of device (mobile/ laptop/ desktop).</p>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="service">
              <img src={require("../img/web.webp")} alt=""/>
                  <h5>Website</h5>
                  <p>High performance web application for rich UX.</p>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="service">
              <img src={require("../img/pwa.webp")} alt=""/>

                  <h5>Progressive Web App Development</h5>
                  <p>Progressive Web Applications (PWA) are a popular digital solution used across a wide range of industries.we have successfully implemented PWA solutions for many clients.PWAs allow you to take advantage of web-based marketing channels to help your business grow.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      </Fade>  )
}

export default ServicePage