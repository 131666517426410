import React from "react";
import SoftwareSkill from "./SoftwareSkill";
import {skillsSection} from "../portfolio";
import {Fade} from "react-reveal";
import '../css/style.css'
// import codingPerson from "../../assets/lottie/codingPerson";
// import DisplayLottie from "../../components/displayLottie/DisplayLottie";
// import StyleContext from "../../contexts/StyleContext";

export default function Skills() {
  // const {isDark} = useContext(StyleContext);
  if (!skillsSection.display) {
    return null;
  }
  return (
    <div className={
      //isDark ? "dark-mode main" : 
    "main mt-4"
    } id="skills">
      <div className="skills-main-div">
        {/* <Fade left duration={1000}>
          <div className="skills-image-div">
            {illustration.animated ? (
              <DisplayLottie animationData={codingPerson} />
            ) : (
              <img
                alt="Man Working"
                src={require("../../assets/images/developerActivity.svg")}
              ></img>
            )}
          </div>
        </Fade> */}
        <Fade right duration={1000}>
        
        <div className="container">
        <div class="col-12">

        <div class="intro">

        <h6>   {skillsSection.title}</h6>
                <h1>{skillsSection.subTitle}</h1>
                </div>
                </div>
            <SoftwareSkill />
        
          </div>
        </Fade>
      </div>
    </div>
  );
}
