import React, { useState } from "react";
import { Fade } from "react-reveal";
// import { Link } from 'react-router-dom';

// import {toast} from 'react-toastify';
import * as emailjs from "emailjs-com";
import { useEffect,useRef } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Contact(history) {

  const [status, setStatus] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if(status === 'SUCCESS') {
      setTimeout(() => {
        setStatus('');
      }, 3000);
    }
  }, [status]);
  const form = useRef();
  const sendEmail = (e) => {
   setIsLoading(true);

    //Faking API call here
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
    e.preventDefault();
    emailjs
      .sendForm(
        'service_3hh7yfx', 'template_zdskgms',      form.current, 'iWbjnp7AyXnwlBWdL'
      )
      .then(
        (result) => {
          // console.log(result.text);
          toast.success('Sent !', {
            position: toast.POSITION.TOP_RIGHT
        })        },
        (error) => {
          console.log(error.text);
          alert("FAILED...", error);
        }
      );
  };


  return (
    <> <style jsx>{`
    .meessage:{
      color:'#fd6754
    }
  `}</style>
      {/* <section className="section-hero p-0">
        <div className="container-fluid p-0">
          <div className="hero-banner ">
            <div className="banner-overlay p-2">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item ">
                    <Link to="/home" className="text-white fw-bold fs-6">
                      Home
                    </Link>
                  </li>
                  <li
                    className="breadcrumb-item active text-white"
                    aria-current="page"
                  >
Contect us                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section> */}
            <Fade right duration={1000}>

        <div className="container" id="contact" >
          <h2 className="secondary-heading text-dark">
            Contact Us
          </h2>
        </div>

        <div className="container  mt-4 mb-4">
          <div className="row">
            <div className="col-md-5 ">
              <div className="">
                <div className="card contact-block shadow  mb-3 bg-white rounded">
                  <div className="card-body " >
                    <div className="d-flex">
                   
                   
                        <i className="fa fa-phone-alt icon-advantage bg-danger"  />
                     
                      <div>
                        <h6 className="ms-3 font-weight-bold text-dark">
                          Get in Touch{" "}
                        </h6>
                        <p className="para-small ms-3">
                          <strong>Email : </strong>{" "}
                          <a href="https://mail.google.com/mail/u/0/?fs=1&to=sales@webiopr.com&body=BODY&tf=cm"
                            className="text-secondary"
                          >
sales@webiopr.com                          </a>
                        </p>
                        <p className="para-small ms-3 ">
                          <strong>Call : </strong>
                          <a href="tel:+919455605492" className="text-secondary">
                            {" "}
                            +91-9455605492{" "}
                          </a>
                       
                        </p>
                        <p className="para-small ms-3 ">

                        <strong> Sheshnath Jaiswal MD, </strong>
                         <a href="tel:+919559219674" className="text-secondary">
                            {" "}
                            +91-9559219674{" "}
                          </a>
                          </p>
                        {/* <p className="para-sm all ms-3 mb-0">Monday to Friday 10 AM to 7 PM</p> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mb-3">
                <div className="card  card contact-block shadow  mb-3 bg-white rounded ">
                  <div className="card-body">
                    <div className="d-flex ">
                    
                        <i className="fa fa-map-marker-alt icon-advantage bg-danger" />
                      
                      <div>
                        <h6 className="ms-3 font-weight-bold text-dark">
                          Office Address
                        </h6>
                        <p className="para-small ms-3 mb-0">
                          Gomti Nagar, Lucknow, Uttar
                          Pradesh, 226010
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Submit a Query form */}
            <div className="col-md-7">
              <div className="row">
                <div className="col-lg-12 col-md-12 text-danger">
                  <h4 className="text-theme-blue ms-3-Query">Submit a Query</h4>
                  <hr />
                </div>
                {status?.type === 'success' && <p class="meessage" >submitted!!</p>}
              </div>
              <form ref={form} onSubmit={sendEmail}>
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-12 mb-3 ">
                    <input
                      type="text"
                      id="name"
                      name="name"
                      className="form-control border"
                      placeholder="Enter Name"
                      required
                    />

                    <small id="nameerror" className="text-danger m-left my-1" />
                  </div>


                  <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
                    <input
                    type="email"
                name="email"
                className="form-control border"
                id="email"
                placeholder="enter your email"
                required
                    />


                    <small
                      id="emailerror"
                      className="text-danger m-left my-1"
                    />

                  </div>


                  <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
                    <input
                        type="number"
                name="contact"
                className="form-control border"
                id="contact"
                placeholder="contact"
                required

                    />


                    <small id="conerror" className="text-danger m-left my-1" />
                  </div>


                  {/* <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
                    <input
                      type="text"
                      id="city"
                      name="city"
                      className="form-control "
                      placeholder="Enter City"
                      onChange={(e) => setCity(e.target.value)}

                    />
                    <small id="suberror" className="text-danger m-left my-1" />
                  </div> */}


                  <div className="col-lg-12 col-md-12 col-sm-12 mb-4">
                    <textarea
                      className="form-control"
                      name="message"
                      placeholder="Message"
                      id="email_body"
                      required

                    />
                  </div>


                  <div className="col-md-3 ">
                    <button
                    disabled={isLoading}
                    // style={{backgroundColor:'#111'}}
                  
                      className="form-control shadow-md bg-danger text-white" 
                     
                    >     {isLoading && (
            <i
              className="fa fa-refresh fa-spin"
              style={{ marginRight: "5px" }}
            />
          )}
          {isLoading && <div class="spinner-border" role="status">
</div>}
          {!isLoading && <span>Submit</span>}
       </button>
                  </div>
                  <ToastContainer />

                  
                </div>
              </form>
            </div>
          </div>
        </div>

        {/* MAP */}
        {/* <div className="container mt-5">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d82904.69602477385!2d80.9863428120659!3d26.852661787373457!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x33e9ebd3d6fdd310!2sBFC%20Capital%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1624256351115!5m2!1sen!2sin"
                width="100%"
                height={400}
                style={{ border: 0 }}
                allowFullScreen
                loading="lazy"
                className="border-3"
              />
            </div>
          </div>
        </div> */}
</Fade>    </>
  );
}

export default Contact;
