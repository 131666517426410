import React from 'react'

function TeamPage() {
  return (

    <section id="team">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="intro">
            <h6>Team</h6>
            <h1>Team Members</h1>
            {/* <p class="mx-auto">Contrary to popular belief, Lorem Ipsum is not simply random text. It has
              roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old</p>
           */}
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-lg-4 col-md-8">
          <div class="team-member">
            {/* <div class="image">
            <img src={require("../img/team_1.jpg")} alt=""/>

                <div class="social-icons">
                  <a href="k"><i class='bx bxl-facebook'></i></a>
                  <a href="l"><i class='bx bxl-twitter'></i></a>
                  <a href="m"><i class='bx bxl-instagram'></i></a>
                  <a href="n"><i class='bx bxl-pinterest'></i></a>
                </div>
                <div class="overlay"></div>
            </div> */}

            <h5>Shesh nath Jaiswal</h5>
            <p>PHP Developer

</p>       </div>
        </div>
        <div class="col-lg-4 col-md-8">
          <div class="team-member">
            {/* <div class="image">
            <img src={require("../img/team_2.jpg")} alt=""/>

                <div class="social-icons">
                <a href="k"><i class='bx bxl-facebook'></i></a>
                  <a href="l"><i class='bx bxl-twitter'></i></a>
                  <a href="m"><i class='bx bxl-instagram'></i></a>
                  <a href="n"><i class='bx bxl-pinterest'></i></a>
                </div>
                <div class="overlay"></div>
            </div> */}

            <h5>Vineet Kushwaha</h5>
            <p>Php Laravel Developer</p>
          </div>
        </div>
        <div class="col-lg-4 col-md-8">
          <div class="team-member">
            {/* <div class="image">
            <img src={require("../img/team_3.jpg")} alt=""/>

                <div class="social-icons">
                <a href="k"><i class='bx bxl-facebook'></i></a>
                  <a href="l"><i class='bx bxl-twitter'></i></a>
                  <a href="m"><i class='bx bxl-instagram'></i></a>
                  <a href="n"><i class='bx bxl-pinterest'></i></a>
                </div>
                <div class="overlay"></div>
            </div> */}

            <h5>Ashish Kumar</h5>
            <p>Android Developer</p>
          </div>
        
        </div>
        <div class="col-lg-4 col-md-8">
          <div class="team-member">
            {/* <div class="image">
            <img src={require("../img/team_3.jpg")} alt=""/>

                <div class="social-icons">
                <a href="k"><i class='bx bxl-facebook'></i></a>
                  <a href="l"><i class='bx bxl-twitter'></i></a>
                  <a href="m"><i class='bx bxl-instagram'></i></a>
                  <a href="n"><i class='bx bxl-pinterest'></i></a>
                </div>
                <div class="overlay"></div>
            </div> */}

            <h5>Ravi Gupta</h5>
            <p>MERN & PHP Developer</p>
          </div>
        
        </div>
        <div class="col-lg-4 col-md-8">
          <div class="team-member">
            {/* <div class="image">
            <img src={require("../img/team_3.jpg")} alt=""/>

                <div class="social-icons">
                <a href="k"><i class='bx bxl-facebook'></i></a>
                  <a href="l"><i class='bx bxl-twitter'></i></a>
                  <a href="m"><i class='bx bxl-instagram'></i></a>
                  <a href="n"><i class='bx bxl-pinterest'></i></a>
                </div>
                <div class="overlay"></div>
            </div> */}

            <h5>Rohit Kumar</h5>
            <p>Dot Net Developer</p>
          </div>
          
        
        </div>
        <div class="col-lg-4 col-md-8">
          <div class="team-member">
            {/* <div class="image">
            <img src={require("../img/team_3.jpg")} alt=""/>

                <div class="social-icons">
                <a href="k"><i class='bx bxl-facebook'></i></a>
                  <a href="l"><i class='bx bxl-twitter'></i></a>
                  <a href="m"><i class='bx bxl-instagram'></i></a>
                  <a href="n"><i class='bx bxl-pinterest'></i></a>
                </div>
                <div class="overlay"></div>
            </div> */}

            <h5>Arpit</h5>
            <p>Business analytic & Operation manager

</p>
          </div>
          
        
        </div>
        <div class="col-lg-4 col-md-8">
          <div class="team-member">
            {/* <div class="image">
            <img src={require("../img/team_3.jpg")} alt=""/>

                <div class="social-icons">
                <a href="k"><i class='bx bxl-facebook'></i></a>
                  <a href="l"><i class='bx bxl-twitter'></i></a>
                  <a href="m"><i class='bx bxl-instagram'></i></a>
                  <a href="n"><i class='bx bxl-pinterest'></i></a>
                </div>
                <div class="overlay"></div>
            </div> */}

            <h5>Geeta Dhoni</h5>
            <p>Business development Manager</p>
          </div>
          
        
        </div>
        <div class="col-lg-4 col-md-8">
          <div class="team-member">
            {/* <div class="image">
            <img src={require("../img/team_3.jpg")} alt=""/>

                <div class="social-icons">
                <a href="k"><i class='bx bxl-facebook'></i></a>
                  <a href="l"><i class='bx bxl-twitter'></i></a>
                  <a href="m"><i class='bx bxl-instagram'></i></a>
                  <a href="n"><i class='bx bxl-pinterest'></i></a>
                </div>
                <div class="overlay"></div>
            </div> */}

            <h5>Adarsh</h5>
            <p>Flutter and React Native Developer</p>
          </div>
          
        
        </div>
      </div>
    </div>
  </section>  )
}

export default TeamPage