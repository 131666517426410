import React from 'react'

function BlogPage() {
  return (
    <section id="blog">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="intro">
            <h6>Blog</h6>
            <h1>Blog Posts</h1>
            <p class="mx-auto">A blog post is any article, news piece, or guide that's published in the blog section of a website.</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <article class="blog-post">
          <img src={require("../assets/images/ai_image.jpg")} alt=""/>
              <a href="o" class="tag">Artificial Intelligence News</a>
              <div class="content">
                <small>26 Sep, 2023</small>
                <h5>Artificial Intelligence: The Future is Now</h5>
                <p>Artificial intelligence (AI) is one of the most transformative technologies of our time. It is already having a major impact on our lives, and its influence is only going to grow in the years to come.

AI is a broad term that encompasses a wide range of technologies. At its core, AI is about creating machines that can think and learn like humans.</p>
              </div>
          </article>
        </div>
        <div class="col-md-4">
          <article class="blog-post">
          <img src={require("../img/project4.jpg")} alt=""/>
              <a href="p" class="tag">Programming</a>
              <div class="content">
                <small>22 Dec, 2022</small>
                <h5>Web Design trends in 2022</h5>
                <p>A web designer works on the appearance, layout, and, in some cases, content of a website. Appearance, for instance, relates to the colors, font, and images used.</p>
              </div>
           </article>
        </div>
        <div class="col-md-4">
          <article class="blog-post">
          <img src={require("../img/project2.jpg")} alt=""/>

              <a href="q" class="tag">Programming</a>
              <div class="content">
                <small>11 Jan, 2022</small>
                <h5>PHP</h5>
                <p>PHP is a server-side scripting language embedded in HTML in its simplest form. PHP allows web developers to create dynamic content and interact with databases. PHP is known for its simplicity, speed, and flexibility — features that have made it a cornerstone in the web development world.

</p>
              </div>
          </article>
        </div>
        <div class="col-md-4">
          <article class="blog-post">
          <img src={require("../img/project2.jpg")} alt=""/>

              <a href="q" class="tag">Programming</a>
              <div class="content">
                <small>11 Jan, 2022</small>
                <h5>PHP</h5>
                <p>PHP is a server-side scripting language embedded in HTML in its simplest form. PHP allows web developers to create dynamic content and interact with databases. PHP is known for its simplicity, speed, and flexibility — features that have made it a cornerstone in the web development world.

</p>
              </div>
          </article>
        </div>
      </div>
    </div>
  </section>
)
}

export default BlogPage