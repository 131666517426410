import React from 'react'
import Headroom from "react-headroom";
import "./Header.scss";

function NavBar() {
  const [isNavCollapsed, setIsNavCollapsed] = React.useState(true);

  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);

  return (
//     <nav class="navbar navbar-expand-lg navbar-light bg-white sticky-top">
//     <div class="container">
//       <a class="navbar-brand" href="/e"> <img 
// width={200} height={50}
//                 alt=""
//                 src={require("../img/images/webiopr.webp")}
//               ></img></a>
//       {/* <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="navbarNav"
//         aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
//         <span class="navbar-toggler-icon"></span>
//       </button> */}
//       <button class="custom-toggler navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded={!isNavCollapsed ? true : false} aria-label="Toggle navigation" onClick={handleNavCollapse}>
//         <span class="navbar-toggler-icon"></span>
//       </button>
//       <div class={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse`} id="navbarNav">
//         <ul class="navbar-nav ms-auto">
//           <li class="nav-item">
//             <a class="nav-link" href="/">Home</a>
//           </li>
//           <li class="nav-item">
//             <a class="nav-link" href="#about">About</a>
//           </li>
//           <li class="nav-item">
//             <a class="nav-link" href="#services">Services</a>
//           </li>
//           <li class="nav-item">
//             <a class="nav-link" href="#portfolio">Portfolio</a>
//           </li>
//           <li class="nav-item">
//             <a class="nav-link" href="#team">Team</a>
//           </li>
//           <li class="nav-item">
//             <a class="nav-link" href="#reviews">Reviews</a>
//           </li>
//           <li class="nav-item">
//             <a class="nav-link" href="#blog">Blog</a>
//           </li>
//         </ul>
//         <a href="#contact"
//           class="nav-link">Contact</a>
//       </div>
//     </div>
//   </nav>


<Headroom>
<header className={
  //isDark ? "dark-menu header" : 

"header"}>
  <a href="/" className="logo">
    {/* <span className="grey-color"> &lt;</span>
    <span className="logo-name">{greeting.username}</span>
    <span className="grey-color">/&gt;</span> */}
    <img 
width={0} height={40}
          alt=""
               src={require("../img/images/webiopr.webp")}
></img>
  </a>
  <input className="menu-btn" type="checkbox" id="menu-btn" />
  <label
    className="menu-icon"
    htmlFor="menu-btn"
    style={{color: "white"}}
  >
    <span className={
      //</label>isDark ? "navicon navicon-dark" : 
      "navicon"}></span>
  </label>
  <ul className={
   // isDark ? "dark-menu menu" :
   "menu"}>

   
    {/* {viewExperience && (
      <li>
        <a href="#experience">Work Experiences</a>
      </li>
    )}
    {viewOpenSource && (
      <li>
        <a href="#opensource">Open Source</a>
      </li>
    )}
    {viewAchievement && (
      <li>
        <a href="#achievements">Achievements</a>
      </li>
    )}
    {viewBlog && (
      <li>
        <a href="#blogs">Blogs</a>
      </li>
    )}
    {viewTalks && (
      <li>
        <a href="#talks">Talks</a>
      </li>
    )} */}
  
              <li class="nav-item">
            <a class="nav-link" href="/">Home</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#about">About</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#services">Services</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#portfolio">Portfolio</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#team">Team</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#reviews">Reviews</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#blog">Blog</a>
          </li>
  </ul>
</header>
</Headroom>
)
}

export default NavBar