import React from 'react'
import {Fade} from "react-reveal";

function Silder() {
  return (
    <Fade right duration={1000}>

    <div class="owl-carousel owl-theme hero-slider">
    {/* <div class="slide slide1">
      <div class="container">
        <div class="row">
          <div class="col-12 text-center text-white">
            <h6 class="text-white text-uppercase">design Driven for professional</h6>
            <h1 class="display-3 my-4">We craft digital<br />experiances</h1>
            <a href="g" class="btn btn-brand">Get Started</a>
            <a href="h" class="btn btn-outline-light ms-3">Our work</a>
          </div>
        </div>
      </div>
    </div> */}
    <div class="slide slide2">
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-10 offset-lg-1 text-white">
            <h6 class="text-white text-uppercase">We craft digital experiances</h6>
            <h1 class="display-3 my-4">Scalable web and 
<br />Mobile App</h1>
            <a href="#about" class="btn btn-brand">Get Started</a>
            <a href="#portfolio" class="btn btn-outline-light ms-3">Our work</a>
          </div>
        </div>
      </div>
    </div>
  </div> 
  </Fade> )
}

export default Silder