import React from 'react'

function AboutPage() {
  return (
    <section id="about">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-5 py-5">
          <div class="row">

            <div class="col-12">
              <div class="info-box">
              <img src={require("../img/icon6.png")} alt=""/>
                  <div class="ms-4">
                    <h5>Digital Marketing</h5>
                    <p>Digital marketing comprises all marketing efforts that use the internet. These include digital channels such as search engines, email, websites, social media, etc., that can be used to connect with current and prospective customers </p>
                  </div>
                
                
                </div>
            </div>
        
            <div class="col-12 mt-4">
              <div class="info-box">
              <img src={require("../img/icon5.png")} alt=""/>

                  <div class="ms-4">
                    <h5>PWA</h5>
                    <p>Progressive Web Apps do not force users to make such hard choices because they are much lighter than native apps.</p>
                  </div>
                </div>
            </div>
            {/* <div class="col-12">
              <div class="info-box">
              <img src={require("../img/icon6.png")} alt=""/>
                  <div class="ms-4">
                    <h5>Digital Marketing</h5>
                    <p>It is a long established fact that a reader will be distracted by the readable
                      content of a page </p>
                  </div>
                
                
                </div>
            </div> */}
            <div class="col-12 mt-4">
              <div class="info-box">
              <img src={require("../img/icon4.png")} alt=""/>
                  <div class="ms-4">
                    <h5>E-Commerce</h5>
                    <p>E-commerce marketing pertains to activities that guide prospects through the conversion funnel.
                    Creating awareness about your e-commerce store
Driving traffic to the store
Converting visitors into paying customers </p>
                  </div>
               </div>
            </div>
            <div class="col-12 mt-4">
              <div class="info-box">
              <img src={require("../img/icon5.png")} alt=""/>

                  <div class="ms-4">
                    <h5>Web And App Development</h5>
                    <p>Web application development is the creation of application programs that reside on remote servers and are delivered to the user’s device over the Internet </p>
                  </div>
                </div>
            </div>
            
          </div>
          
        </div>

        <div class="col-lg-5 mt-5">
          <img className=' rounded-circle' src={require("../img/images/potf2.jpg" )}alt=""/>
         </div>
      </div>
    </div>
  </section>  )
}

export default AboutPage