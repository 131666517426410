import React from 'react'

function Step() {
  return (
    <> <style jsx>{`

.block{
            border: 1px solid red;
            text-align: center;
            vertical-align: middle;
        }
        .circle{
            background: red;
            border-radius: 100px;
            color: white;
            height: 100px;
            font-weight: bold;
            width: 100px;
        }
		.circle span{
			display: table-cell;
			padding-top:25%;
		}
        .spen{
			padding-top:35%;
		}  `}</style>
  <div class="row mb-5">

  <div class="col-md-2">
        	<p class="circle" align="center"><span>Some Text here Some text here Some text here</span></p>
        </div>

        <div class="col-md-2">
            
            <div class="spen">
                <hr/>
            </div>
         
        </div>
        <div class="col-md-2">
        	<p class="circle" align="center"><span>Some Text here Some text here Some text here</span></p>
        </div>
        <div class="col-md-2">
            
            <div class="spen">
                <hr/>
            </div>
         
        </div>
        <div class="col-md-2">
        	<p class="circle" align="center"><span>Some Text here Some text here Some text here</span></p>
        </div>
        
</div>
       </>

  )
}

export default Step