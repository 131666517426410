import React from 'react'
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';

function MileStonePage() {
  
  return (
    <section id="milestone">
    <div class="container">
      <div class="row text-center justify-content-center gy-4">
        <div class="col-lg-2 col-sm-6">
          <h1 class="display-4"><CountUp start={0} end={115} delay={0.5} redraw={true}>
  {({ countUpRef,start }) => (
    <VisibilitySensor onChange={start} delayedCall>
                <span ref={countUpRef} />
            </VisibilitySensor>
  )}
</CountUp></h1>
          <p class="mb-0">Happy Clients</p>
        </div>
        <div class="col-lg-2 col-sm-6">
          <h1 class="display-4"><CountUp start={0} end={25} delay={1} redraw={true}>
          {({ countUpRef,start }) => (
    <VisibilitySensor onChange={start} delayedCall>
                <span ref={countUpRef} />
            </VisibilitySensor>
  )}
</CountUp>
</h1>
          {/* <p class="mb-0">Lines of code</p>
        </div>
        <div class="col-lg-2 col-sm-6">
          <h1 class="display-4"><CountUp start={0} end={70} delay={2} redraw={true}>
          {({ countUpRef,start }) => (
    <VisibilitySensor onChange={start} delayedCall>
                <span ref={countUpRef} />
            </VisibilitySensor>
  )}
</CountUp></h1> */}
          <p class="mb-0">Our Customer</p>
        </div>
        <div class="col-lg-2 col-sm-6">
          <h1 class="display-4"><CountUp start={0} end={35} delay={1} redraw={true}>
          {({ countUpRef,start }) => (
    <VisibilitySensor onChange={start} delayedCall>
                <span ref={countUpRef} />
            </VisibilitySensor>
  )}
</CountUp></h1>
          <p class="mb-0">Project Complete
</p>
        </div>
      </div>
    </div>
  </section>  )
}

export default MileStonePage