import React from 'react'

function Footer() {
  return (
    <footer>
    <div className="footer-top text-center">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6 text-center">
            <h4 className="navbar-brand">Webiopr Tech Solution (WTS) Private Limited</h4>
            <p>Gomti Nagar</p>
            <p>Lucknow, Uttar Pradesh 226016</p>
            <li className="text-footer"><a href="tel:+919455605492"><i classNameName="fa fa-phone-alt me-2" aria-hidden="true" />
                            {" "}
                           +91-9559219674{" "}
                          </a></li>
            <li className="text-footer"><a href="tel:+919455605492"><i classNameName="fa fa-phone-alt me-2" aria-hidden="true" /> +91-9455605492 </a> </li>
            <li className><a href="https://mail.google.com/mail/u/0/?fs=1&to=sales@webiopr.com&body=BODY&tf=cm"><i classNameName="fa fa-envelope me-2" aria-hidden="true" />sales@webiopr.com </a> </li>
            
            {/* <div className="col-auto social-icons">
            <a href="k"><i className='bx bxl-facebook'></i></a>
                  <a href="l"><i className='bx bxl-twitter'></i></a>
                  <a href="m"><i className='bx bxl-instagram'></i></a>
                  <a href="n"><i className='bx bxl-pinterest'></i></a>
            </div> */}
            {/* <div className="col-auto conditions-section">
              <a href="r">privacy</a>
              <a href="s">terms</a>
              <a href="t">disclaimer</a>
          </div> */}
        </div>
      </div>
    </div>
    </div>
  <div className="footer-bottom text-center">
    <p className="mb-0">© Copyright 2023, All Rights Reserved.
</p>  </div>
<div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div className="modal-dialog modal-dialog-centered modal-xl">
      <div className="modal-content">
        <div className="modal-body p-0">
          <div className="container-fluid">
            <div className="row gy-4">
              <div className="col-lg-4 col-sm-12 bg-cover"
                // style={"background-image: url(img/c2.jpg); min-height:300px;"}
                >
                <div>

                </div>
              </div>
              <div className="col-lg-8">
                <form className="p-lg-5 col-12 row g-3">
                  <div>
                    <h1>Get in touch</h1>
                    <p>Fell free to contact us and we will get back to you as soon as possible</p>
                  </div>
                  <div className="col-lg-6">
                    <label for="userName" className="form-label">First name</label>
                    <input type="text" className="form-control" placeholder="Jon" id="userName"
                      aria-describedby="emailHelp" />
                  </div>
                  <div className="col-lg-6">
                    <label for="userName" className="form-label">Last name</label>
                    <input type="text" className="form-control" placeholder="Doe" id="userName"
                      aria-describedby="emailHelp" />
                  </div>
                  <div className="col-12">
                    <label for="userName" className="form-label">Email address</label>
                    <input type="email" className="form-control" placeholder="Johndoe@example.com"
                      id="userName" aria-describedby="emailHelp" />
                  </div>
                  <div className="col-12">
                    <label for="exampleInputEmail1" className="form-label">Enter Message</label>
                    <textarea name="" placeholder="This is looking great and nice."
                      className="form-control" id="" rows="4"></textarea>
                  </div>

                  <div className="col-12">
                    <button type="submit" className="btn btn-brand">Send Message</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</footer>  )
}

export default Footer